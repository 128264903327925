import React from 'react'
import Hero from './Hero'
import Navbar from './Navbar'
import Featured from './Featured'
import Signup from './Signup'
import Footer from './Footer'
import Advantages from './Advantages'
import Develo from './Develo'
import Adva from './Adva'
import Download from './Download'
import Help from './Help'
import Earn from './Earn'
import About from './About'


const Home = () => {
    return (
        <div>
           <Navbar/>
           <Hero/>
           <Featured/>
           <Advantages/>
           <Signup/>
           <Adva/>
           <Develo/>
           <About/>
           <Download/>
           <Help/>
           <Earn/>
           <Footer/>

        </div>
    )
}

export default Home
