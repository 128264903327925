import React from 'react'
import Navbar from './Navbar'
import Roadmap from './Roadmap'


const Home = () => {
    return (
        <div>
           <Navbar/>
           <Roadmap/>
        </div>
    )
}

export default Home
