import React from 'react'
import Hero from './Hero'
import Navbar from './Navbar'
import Featured from './Featured'
import Signup from './Signup'
import Footer from './Footer'

import DownloadPage from './Download'


const Home = () => {
    return (
        <div>
           <Navbar/>
          <DownloadPage/>
           <Footer/>
        </div>
    )
}

export default Home
