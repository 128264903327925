import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {FaBars, FaTimes, FaDownload} from 'react-icons/fa'
import { IoDownloadOutline } from 'react-icons/io5'
import { RiCustomerServiceLine } from 'react-icons/ri'
import Crypto from '../assets/qr-scan.png'
import icon from '../assets/czg-icon.png'

import './Navbar.css'

const Navbar = () => {
const [click, setClick] = useState(false)
const handleClick = () => setClick(!click)


    return (
        <div className='header'>
            <div className='container'>
                <img className='icon' src={icon}>
                
                </img>
                <span className='nav-pc'>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    
                    <li>
                        {/* <a   href='/Hero'>Home</a> */}
                    </li>
                    <li>
                    <a href='/'>Home</a>
                        {/* <a href='/Hero'>Home</a> */}
                    </li>
                    <li>
                        <a href='https://cryptozg.com' target="_blank">Trade</a>
                    </li>
                    <li>
                        <a  href='/Roadmap'>Roadmap</a>
                    </li>

                    <li>
                        <a  href='/privacy-policy'>Privacy Policy</a>
                    </li>
                    <li>
                        <a  href='/help'>Help</a>
                    </li>
                    <li>
                        <a style={{color:"#fcd535"}} href='https://cryptozg.com/v2/signin' target="_blank">Login</a>
                    </li>
                    <li>
                        <a style={{color:"black", padding:"7px", backgroundColor:"#fcd535", border: "solid", borderRadius: "8px"}} href='https://cryptozg.com/v2/signup'>Register</a>
                    </li>
                    <li>
                       <a href='/download' className='qr-scan-pc' > 
                       <span style={{color: "white", fontSize: "20px" }} >
                        < IoDownloadOutline/></span>
                        </a> 
                        <img className='img-download' src={Crypto} alt=''/>    
                    </li>
                </ul>
                </span>
                <span className='nav-phone'>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <center>
                    <li>
                        <a style={{color:"#fcd535"}} href='https://cryptozg.com/v2/signin' target="_blank">Login</a>
                    </li>
                    <li>
                    <a href='https://cryptozg.com/v2/signup' target="_blank">
                        <button style={{color:"black", marginTop:"-10px"}} className='btn'>Register</button>
                    </a>
                    </li>
                    </center>
                    
                    <li>
                    <a href='/'>Home</a>
                        {/* <a href='/Hero'>Home</a> */}
                    </li>
                    <li>
                        <a href='https://cryptozg.com' target="_blank">Trade</a>
                    </li>
                    <li>
                        <a  href='/Roadmap'>Roadmap</a>
                    </li>

                    <li>
                        <a  href='/privacy-policy'>Privacy Policy</a>
                    </li>
                    <li>
                        <a  href='/help'>Help</a>
                    </li>
                    <li>
                       <a href='/download' className='qr-scan-phone' > 
                       <span style={{color: "white", fontSize: "20px" }} >
                            Download
                        < IoDownloadOutline/></span>
                        </a> 
                        <img className='img-download' src={Crypto} alt=''/>    
                    </li>
                    
                    
                </ul>
                </span>
                <div className='btn-group'>
                    {/* <button className='btn'>Connect Wallet</button> */}
                </div>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={20} style={{color: 'white'}}/>) : (<FaBars size={20} style={{color: '#ffffff'}} />)}
                    
                </div>
            </div>
        </div>
    )
}

export default Navbar
