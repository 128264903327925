import React from 'react'
import './About.css'
import Crypto from '../assets/3rd.png'


const About = () => {
    return (
        <div className='hari'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
               
                    <h2>Company</h2>
                   <p> - 3 Years Blockchain industry experience
201 people Technical team
2000 Cooperative agency</p>
<p> - 3 years Practical experience in security system operation
1040 people Established
52 Branch</p>

                    {/* <p>Obtained a Serbian business license, led the innovation of blockchain technology, and promoted the global industrialization of blockchain technology.</p>
                    <p>Headquartered in Serbia, it has independent trading business and operation centers in San Francisco, London, Osaka, Paris, Singapore and other countries and regions. </p>
                    <p> In line with ending chaos in the industry, formulating industry rules, and guiding the vision of healthy Aboutelopment of the industry, we hired the original IBM technical team to create the world's first securities-level trading platform, committed to providing users with safe, convenient, fair and just digital assets Trading experience.</p> */}
                    
                    {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
                    </div> */}
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div>
                    {/* <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default About
