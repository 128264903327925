import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import BTC from '../assets/btc-img.png'
import { FiArrowUpRight, FiArrowDown } from 'react-icons/fi'
import './Develo.css'
import chu from '../assets/1.png'
import Crypto from '../assets/2.png'
import chuya from '../assets/3.png'
import port from '../assets/4.png'
import pip from '../assets/5.png'
import six from '../assets/6.png'
const Develo = () => {

    const [data, setData] = useState(null)

    const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=6&page=1&sparkline=false'

    useEffect(() => {
        axios.get(url).then((response) => {
            setData(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    // console.log(data)

    if (!data) return null

    return (
        <div className='Develo'>
            <div className='container'>
                {/* Left */}
                <div className='left'>
                    <h2 >Meet the Team</h2>
                    <p style={{color: "white"}} >CryptoZG is an all-in-one solution for cryptocurrencies. It has been serving the needs of blockchain enthusiasts ever since the dawn of cryptocurrencies</p>
                    {/* <button className='btn'>See More Coins</button> */}
                </div>

                {/* Right */}

                <div className='right'>
                    <div className='card'>
                        <div className='top'>
                            {/* <img src={BTC} alt='/' /> */}
                            <img src={Crypto} alt=''/>
                        </div>
                        <div>
                            <h5 style={{color: "#fcd535"}}>Justas Dobiliauskass</h5>
                            <p style={{color: "white"}}>Co Founder</p>
                        </div>

                        {/* {data[0].price_change_percentage_24h < 0 ? (
                            <span className='red'>
                                <FiArrowDown className='icon' />
                                {data[0].price_change_percentage_24h.toFixed(2)}%
                            </span>
                        ) : (
                                <span className='green'>
                                    <FiArrowUpRight className='icon' />
                                    {data[0].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            )} */}
                    </div>
                    <div className='card'>
                        <div className='top'>
                        <img src={chu} alt=''/>
                        </div>
                        <div>
                            <h5 style={{color: "#fcd535"}}>Vytautas Karalevičius</h5>
                            <p style={{color: "white"}}>Co Founder</p>
                        </div>

                        {/* {data[1].price_change_percentage_24h < 0 ? (
                            <span className='red'>
                                <FiArrowDown className='icon' />
                                {data[1].price_change_percentage_24h.toFixed(2)}%
                            </span>
                        ) : (
                                <span className='green'>
                                    <FiArrowUpRight className='icon' />
                                    {data[1].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            )} */}
                    </div>
                    <div className='card'>
                        <div className='top'>
                        <img src={chuya} alt=''/>
                        </div>
                        <div>
                            <h5 style={{color: "#fcd535"}}>Mantas Mockevičius</h5>
                            <p style={{color: "white"}}>Co Founder</p>
                        </div>

                        {/* {data[2].price_change_percentage_24h < 0 ? (
                            <span className='red'>
                                <FiArrowDown className='icon' />
                                {data[2].price_change_percentage_24h.toFixed(2)}%
                            </span>
                        ) : (
                                <span className='green'>
                                    <FiArrowUpRight className='icon' />
                                    {data[2].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            )} */}
                    </div>
                    <div className='card'>
                        <div className='top'>
                        <img src={port} alt=''/>
                        </div>
                        <div>
                            <h5 style={{color: "#fcd535"}}>Julius Demenius</h5>
                            <p style={{color: "white"}}>Developer</p>
                        </div>

                        {/* {data[3].price_change_percentage_24h < 0 ? (
                            <span className='red'>
                                <FiArrowDown className='icon' />
                                {data[3].price_change_percentage_24h.toFixed(2)}%
                            </span>
                        ) : (
                                <span className='green'>
                                    <FiArrowUpRight className='icon' />
                                    {data[3].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            )} */}
                    </div>
                    <div className='card'>
                        <div className='top'>
                        <img src={pip} alt=''/>
                        </div>
                        <div>
                            <h5 style={{color: "#fcd535"}}>Justas Dragūnas</h5>
                            <p style={{color: "white"}}>Developer</p>
                        </div>

                        {/* {data[0].price_change_percentage_24h < 0 ? (
                            <span className='red'>
                                <FiArrowDown className='icon' />
                                {data[4].price_change_percentage_24h.toFixed(2)}%
                            </span>
                        ) : (
                                <span className='green'>
                                    <FiArrowUpRight className='icon' />
                                    {data[4].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            )} */}
                    </div>
                    <div className='card'>
                        <div className='top'>
                        <img src={six} alt=''/>
                        </div>
                        <div>
                            <h5 style={{color: "#fcd535"}}>Neringa Freitakaitė</h5>
                            <p style={{color: "white"}}>Developer</p>
                        </div>

                        {/* {data[5].price_change_percentage_24h < 0 ? (
                            <span className='red'>
                                <FiArrowDown className='icon' />
                                {data[5].price_change_percentage_24h.toFixed(2)}%
                            </span>
                        ) : (
                                <span className='green'>
                                    <FiArrowUpRight className='icon' />
                                    {data[5].price_change_percentage_24h.toFixed(2)}%
                                </span>
                            )} */}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Develo
