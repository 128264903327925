import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import icon from '../assets/czg-icon.png'

import {FaFacebook, FaGithub, FaLinkedin, FaTwitter} from 'react-icons/fa'
import './Footer.css'


function Footer() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='footer'>

            <div className='container'>
            <div className='col col-1'>
            <img className='icon' src={icon}></img>
                    <h4 style={{color: "#fcd535"}}> 2023 - CryptoZG</h4>     
          </div>
          </div>
            <div className='container'>
                {/* <div className='col'>
                    <h5 style={{color: "white"}}>Support</h5>
                    <span className='bar'></span>
                        <a href='/'>Contact Us</a>
                        <a href='/'>Chat</a>
                        <a href='/'>Help Center</a>
                        <a href='/'>FAQ</a>

                </div> */}
                {/* <div className='col'>
                    <h5 style={{color: "white"}}>Developers</h5>
                    <span className='bar'> </span>
                        <a href='/'>Cloud</a>
                        <a href='/'>Commerce</a>
                        <a href='/'>Pro</a>
                        <a href='/'>API</a>
                   
                </div> */}
                {/* <div className='col'>
                    <h5 style={{color: "white"}}>Company</h5>
                    <span className='bar'> </span>
                        <a href='/'>About</a>
                        <a href='/'>Information</a>
                        <a href='/'>Legal</a>
                        <a href='/'>Privacy</a>
                </div> */}
                {/* <div className='col'>
                    <h5 style={{color: "white"}}>Social</h5>
                    <span className='bar'> </span>
                        <a href='/'><FaFacebook className='icon'/></a>
                        <a href='/'><FaTwitter className='icon'/></a>
                        <a href='/'><FaLinkedin className='icon'/></a>
                        <a href='/'><FaGithub className='icon'/></a>
                </div> */}
            </div>
        </div>
    )
}

export default Footer
