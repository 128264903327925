import React from 'react'
import './Help.css'
import Crypto from '../assets/download.png'
import help from '../assets/help-center.png'
import Aw from '../assets/dl.png'
import Iw from '../assets/apple.svg'
import { Link } from "react-router-dom";

import CryptoZG from './CryptoZG.mobileconfig'
import CZGAndroid from './CZG.apk'

const Hero = () => {

    return (
        <div className='help'>
            <div className='container'>

                {/* Left Side */}
                
                <div className='left'>
                    <h2>Help</h2>
                    <h3>24/7 Chat Support</h3>
                    <p>Get 24/7 chat support with our friendly customer service agents at your service.</p>
                    <a href='https://direct.lc.chat/14603253/'>
                        <button className='btn-android'>Chat Now</button>
                    </a>
                        
                            

                    {/* <a href={'https://drive.google.com/file/d/1oYSas6dhoIdpSFyFGceg-CKkBus_7Rk8/view?usp=share_link'} target="_blank">
                        <button className='btn-apple'>Apple</button>
                    </a> */}
                
                    <div className='input-container'>
                        {/* <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button> */}
                    </div>
                </div>

                {/* Right Side */}
                

                <div className='right'>
           
                    <div className='img-container'>
                        <img src={help} alt=''/>
                    </div>
                
                </div>
            </div>
        </div>
    )
}



export default Hero


