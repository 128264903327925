import React from 'react'
import './Earn.css'
import Crypto from '../assets/download.png'
import Earn from '../assets/Earn.png'
import Aw from '../assets/dl.png'
import Iw from '../assets/apple.svg'
import { Link } from "react-router-dom";

import CryptoZG from './CryptoZG.mobileconfig'
import CZGAndroid from './CZG.apk'

const Hero = () => {

    return (
        <div className='earn'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
           
                    <div className='img-container'>
                        <img src={Earn} alt=''/>
                    </div>
                
                </div>


                {/* Right Side */}
                <div className='right'>
                    <h2>Start Earning Today!</h2>
                    <p>Sign up and earn today.</p>
                    <a href="https://cryptozg.com/v2/signup">
                        <button className='btn'>Sign Up</button>
                    </a>
                            

                    {/* <a href={'https://drive.google.com/file/d/1oYSas6dhoIdpSFyFGceg-CKkBus_7Rk8/view?usp=share_link'} target="_blank">
                        <button className='btn-apple'>Apple</button>
                    </a> */}
                
                    <div className='input-container'>
                        {/* <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Hero


