import React from 'react'
import './Adva.css'
import Crypto from '../assets/2nd.png'


const Adva = () => {
    return (
        <div className='heroza'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
               
                    <h2>About Us</h2>
                    <p>CryptoZG is an investment platform where we grow your cryptocurrencies. It is committed in creating the most secure, stable and efficient digital currency trading platform for users around the world. CryptoZG is a reliable app. CryptoZG means Network CryptoZG its core team comes from a well-known Internet and Financial companies in the world, including many of the practioners and investors in the digital currency industry. CryptoZG, a safe, fast and concise trading platfrom for cryptocurrency trading Our company Network CryptoZG comes from well-known Internet and financial companies in the world, including many of the earliest practitioners and investors in the digital currency industry. Our company Network CryptoZG comes from well-known Internet and financial companies in the world, including many of the earliest practitioners and investors in the digital currency industry. Our platform focuses on allowing people to rent a mining rig that produces Doge and BTC coins. And there are also other investment plan that you can avail. CryptoZG is an investment platform where we grow your cryptocurrencies. It is committed in creating the most secure, stable and efficient digital currency trading platform for users around the world.</p>
                    {/* <p>Obtained a Serbian business license, led the innovation of blockchain technology, and promoted the global industrialization of blockchain technology.</p>
                    <p>Headquartered in Serbia, it has independent trading business and operation centers in San Francisco, London, Osaka, Paris, Singapore and other countries and regions. </p>
                    <p> In line with ending chaos in the industry, formulating industry rules, and guiding the vision of healthy Advaelopment of the industry, we hired the original IBM technical team to create the world's first securities-level trading platform, committed to providing users with safe, convenient, fair and just digital assets Trading experience.</p> */}
                    
                    {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
                    </div> */}
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div>
                    {/* <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Adva
