import React from 'react'
import Hero from './Hero'
import Navbar from './Navbar'
import Featured from './Featured'
import Help from './Help'
import Footer from './Footer'




const Home = () => {
    return (
        <div>
           <Navbar/>
          <Help/>
           <Footer/>
        </div>
    )
}

export default Home
