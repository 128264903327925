import React from 'react'
import './Puter.css'
import Crypto from '../assets/wan.png'
import Kas from '../assets/twu.png'
import Kbs from '../assets/tri.png'
import Kcs from '../assets/pour.png'
import Kds from '../assets/payib.png'

const Puter = () => {
    return (
        <div className='heroput'>
            <div className='container'>

        
                <div className='left'>
               
                    <h2>Cooperative Agency</h2>
                   
                    
                   
                </div>


            
                <div className='right'>
                    <div className='img-container'>
                        <img src={Crypto} alt='' to="https://github.com/Joeyryanbridges"/>
                    </div>
                    <div className='img-container'>
                        <img src={Kas} alt=''/>
                    </div>
                    <div className='img-container'>
                        <img src={Kbs} alt=''/>
                    </div>
                    <div className='img-container'>
                        <img src={Kcs} alt=''/>
                    </div>
                    <div className='img-container'>
                        <img src={Kds} alt=''/>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Puter
