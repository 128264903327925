import React from 'react'
import Navbar from './Navbar'
import Ex from './Ex'


const Home = () => {
    return (
        <div>
           <Navbar/>
           <Ex/>
        </div>
    )
}

export default Home
