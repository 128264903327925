import React from 'react'
import './Hero.css'
import Crypto from '../assets/logo.png'
import Aw from '../assets/dl.png'
import Iw from '../assets/apple.svg'
import { Link } from "react-router-dom";

import CryptoZG from './CryptoZG.mobileconfig'
import CZGAndroid from './CZG.apk'

const Hero = () => {

    return (
        <div className='hero'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
                    <p>Buy & Sell Crypto 24/7 using CryptoZG</p>
                    <h1>Invest in Cryptocurreny with Your CryptoZG</h1>
                    <p>Buy, Sell, and store your cryptocurrencies</p>

                    <a href={'https://cryptozg.com'} target="_blank">
                        <button className='btn'>Trade Now</button>
                    </a>

                    {/* <a href={CZGAndroid}>
                        <button className='btn-android'>Android</button>
                    </a>

                    <a href={CryptoZG}>
                        <button className='btn-apple'>Apple</button>
                    </a> */}
                            

                    {/* <a href={'https://drive.google.com/file/d/1oYSas6dhoIdpSFyFGceg-CKkBus_7Rk8/view?usp=share_link'} target="_blank">
                        <button className='btn-apple'>Apple</button>
                    </a> */}
                
                    <div className='input-container'>
                        {/* <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button> */}
                    </div>
                </div>


                {/* Right Side */}
                <div className='right'>
           
                    <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div>
                
                </div>
            </div>
        </div>
    )
}



export default Hero


