import React from 'react'
import './Advantages.css'
import Crypto from '../assets/22nd.png'

const Advantages = () => {
    return (
        <div className='heroku'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
               
                    <h2>Core Advantages</h2>
                  <center>  <h4> Safety </h4> </center>  
                    <p>  Based on the IBM enterprise-level security architecture, using multi-layer hardware isolation.
 A 24-hour real-time early warning system detects potential cyber attacks.
  Uses the world's leading security protection services outside the system.
 Uses multiple network isolation inside the system to prevent intrusion.</p>
                <center>  <h4> 
High Concurrency </h4> </center>  

                    <p> The most advanced distributed system design.
 Dynamically adjust hardware resources according to load conditions.
 Support real-time online transactions for millions of users.</p>

 <center>  <h4> 
 Globalization </h4> </center>  
                    <p> Uninterrupted operation of global distributed servers.
 Globaly multilingual, multi-regional, multi-currency support.</p>
 
 <center>  <h4> 
 Saving Money</h4> </center>  
                    <p>Provide millisecond-level accurate pen, time, and snapshot data.
 Provides streaming data subscription interface for institutional investors.
 Real-time remote backup to ensure data security.</p>
                    
                    {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
                    </div> */}
                </div>


                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div>
                    {/* <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Advantages
