import React from 'react'
import Featured from './components/Featured'
import Help from './components/HelpContainer'
import Navbar from './components/Navbar'
import Signup from './components/Signup'
import Footer from './components/Footer'
 import Advantages from './components/Advantages'
 import Develo from './components/Develo'
 import Adva from './components/Adva'
 import About from './components/About'
 import Roadmap from './components/RoadmapContainer'
 import Download from './components/DownloadContainer'
 import PrivacyPolicy from './components/PrivacyPolicy'
 import Home from './components/Home'
 import { Link,Routes, Route} from "react-router-dom"
// import 'bootstrap/dist/css/bootstrap.min.css';


import Dev from './components/Dev'
import Puter from './components/Puter'



function App() {
  return (
    <>

<nav> 
<ul>
<li>
</li>
</ul>
</nav>
    <Routes> 
      <Route path   ="/" element={<Home/>} /> 
      <Route path   ="/roadmap" element={<Roadmap/>} /> 
      <Route path   ="/download" element={<Download/>} /> 
      <Route path   ="/help" element={<Help/>} />
      <Route path   ="/privacy-policy"element={<PrivacyPolicy/>} />
    </Routes>
    

      
  
    </>
  );
  
}




export default App;
