import React from 'react'
import './Dev.css'
import Crypto from '../assets/1st.png'


const Dev = () => {
    return (
        <div className='hero1'>
            <div className='container'>

                {/* Left Side */}
                <div className='left'>
               
                    <h2>Kick off Your Crypto Journey with us</h2>
                    <p>CryptoZG is the world's first open digital asset trading platform. Preparations began in 2019, after 3 years of continuous development and testing.</p>
                    <p>Obtained a Serbian business license, led the innovation of blockchain technology, and promoted the global industrialization of blockchain technology.</p>
                    <p>Headquartered in Serbia, it has independent trading business and operation centers in San Francisco, London, Osaka, Paris, Singapore and other countries and regions. </p>
                    <p> In line with ending chaos in the industry, formulating industry rules, and guiding the vision of healthy development of the industry, we hired the original IBM technical team to create the world's first securities-level trading platform, committed to providing users with safe, convenient, fair and just digital assets Trading experience.</p>
                    
                    {/* <div className='input-container'>
                        <input type='email' placeholder='Enter your email' />
                        <button className='btn'>Learn More</button>
                    </div> */}
                </div>
                



                {/* Right Side */}
                <div className='right'>
                    <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div>
                    {/* <div className='img-container'>
                        <img src={Crypto} alt=''/>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Dev
