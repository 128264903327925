import React from 'react'
import './Roadmap.css'
import RoadmapPng from '../assets/roadmap.png'
import passion from '../assets/passion.png'
import product from '../assets/product.png'
import start from '../assets/start.png'
import validation from '../assets/validation.png'
import adoptation from '../assets/adoptation.png'
import grow from '../assets/grow.png'
import big from '../assets/big.png'
import Navbar from './Navbar'
import Aw from '../assets/dl.png'
import Iw from '../assets/apple.svg'
import { Link } from "react-router-dom";

const Hero = () => {
    return (
        <div className='hero'>
            <div className='container'>
                <div className='img-container-banner'>
                    <img className="img-banner" src={RoadmapPng} alt=''/>
                </div>       
            </div>
            <div className='container'>
                <div className='left'>
                <h2>Passion</h2>
                <p>CryptoZG's ultimate goal is enabling human to access the new financial world in an open protocol.</p><br/>
                <p>We use the most sophisticated, industry-leading technology security protocols available on the market. All information stored, shared or uploaded on the platform is encrypted with the most advanced tools available.</p>
                </div>

                <div className='right'>
                <img className="img-banner" src={passion} alt=''/>
                </div>
                
                {/* <p>We use the most sophisticated, industry-leading technology security protocols available on the market. All information stored, shared or uploaded on the platform is encrypted with the most advanced tools available.</p> */}
            </div>
            <div className='container'>
                <div className='left'>
                <img className="img-banner" src={product} alt=''/>
                </div>

                <div className='right'>
                <h2>Products</h2>
                <p>CryptoZG is trying to connect individuals, funds and knowledge to advance global economies through the use of cryptocurrency. We provide market-leading technology solutions and analytics to assist our users thrive in today's global digital and new economy world.</p><br/>
                <p>We use the most sophisticated, industry-leading technology security protocols available on the market. All information stored, shared or uploaded on the platform is encrypted with the most advanced tools available.</p>
                <br/>
                <p>Cutting Edge Innovative Technology: In the cryptocurrency market, a fraction of a second can mean the difference between a profit and a loss. CryptoZG provides a next generation high performance trading platform that can protect the entire value chain, end to end. To do this, CryptoZG redesigned our trading platform from the ground up.</p>
                <p>Lower Cost: CryptoZG started out with a mission of cutting out fat that makes other trading platforms costly. With our transparent, technology driven, low commissions and best price executions to minimize your cost and to maximize your returns.

Secure: CryptoZG uses state-of-the-art security measures when handling your information and transactions. Your sensitive personal information is fully encrypted and securely stored.

Simplicity: Today, there is complex system of cryptocurrency trading that may seem daunting to newcomers. Making CryptoZG simple, focus and immediately understable is hard work. But we believing that by sticking to this goal, we can make something really special.</p>
                </div>
                
                {/* <p>We use the most sophisticated, industry-leading technology security protocols available on the market. All information stored, shared or uploaded on the platform is encrypted with the most advanced tools available.</p> */}
            </div>
        </div>

        
    )
}

export default Hero
